import $ from 'jquery'
import Popup from '../modules/module.popup'
import Tab from '../modules/module.tab'
import Form from '../modules/module.validate'
import Inputmask from 'inputmask'
import ObjectFit from '../modules/module.objectfit'
import Scrollbar from '../modules/module.scrollbar'
import Viewport from '../modules/module.viewport'
import StickySidebar from 'sticky-sidebar'
import 'slick-carousel'


window.app = window.app || {};

if ("ontouchstart" in document.documentElement){
	document.body.className += ' touch';
}


const form = new Form();

[].forEach.call(
	document.querySelectorAll('.form'),
	(el)=>{

		// console.log(el);
		let wrong = false;

		[].forEach.call(
			el.querySelectorAll('input'),
			(input)=>{
				if (input.hasAttribute('data-require')){
					let rule = input.getAttribute('data-require');
					if ( rule ){
						form.validate(input, rule, err =>{ if (err.errors.length) { wrong = true; } });
						input.addEventListener('keyup', check, false);
						input.addEventListener('input', check, false);
						input.addEventListener('change', check, false);
					}

					function check() {
						wrong = false;

						[].forEach.call(
							el.querySelectorAll('input'),
							(input)=>{
								if (input.hasAttribute('data-require')){
									let rule = input.getAttribute('data-require');
									if ( rule ) form.validate(input, rule, err =>{ if (err.errors.length) {wrong = true;} });
								}
							});

						if ( wrong ){
							el.dataset.formValidateError = 'true';
						} else {
							el.removeAttribute('data-form-validate-error');
						}
					}
				}
			});

		if ( wrong ){
			el.dataset.formValidateError = 'true';
		} else {
			el.removeAttribute('data-form-validate-error');
		}

	});


$(function () {

	Inputmask.extendDefinitions({
		'%': {  //masksymbol
			"validator": "[.0-9A-Za-zА-яЁёÀ-ÿµ_-]"
		}
	});

	window.app.popup = new Popup({
		bodyClass: 'body-popup',
		onPopupOpen: (pop)=>{
			// console.info(pop);
			$(pop).find('.is-registered').removeClass('is-registered');
			$(pop).find('[data-registered]').removeAttr('data-registered');
		}
	});

	new ObjectFit();
	new Tab({
		classActiveLink: 'is-active',
		classActiveTab: 'is-active',
		onTabChange: (tab)=>{
			// console.info(tab);
			$(tab).removeClass('is-registered');
			$(tab).find('[data-registered]').removeAttr('data-registered');
		}
	});

	const scrollbar = new Scrollbar();

	scrollbar.destroy('.newsfeed__list');
	scrollbar.init('.newsfeed__list');

	new Viewport({
		'0': ()=>{
			scrollbar.destroy('.newsfeed__list');
			scrollbar.init('.newsfeed__list',{
				axis:"x",
				advanced:{
					autoExpandHorizontalScroll:true
				}
			});

		},
		'1010': ()=>{
			scrollbar.destroy('.newsfeed__list');
			scrollbar.init('.newsfeed__list');
		}
	});


	if ( $('.section-article__aside').length ){
		setTimeout(()=>{
			new StickySidebar('.section-article__aside', {
				topSpacing: $('.nav__scroll').length ? 80 : 20,
				bottomSpacing: 20,
				containerSelector: '.section-article .inner',
				innerWrapperSelector: '.section-article__aside-inner'
			});
		}, 10);
	}


	$('.card_carousel').each(function () {
		let $wrap = $(this),
			$slider = $wrap.find('.card__list')
		;

		$slider.slick({
			dots: false,
			infinite: true,
			swipeToSlide: true,
			speed: 300,
			prevArrow: $wrap.find('.card__control-btn_prev'),
			nextArrow: $wrap.find('.card__control-btn_next'),
			variableWidth: false,
			slidesToScroll: 1,
			mobileFirst: true,

			responsive: [
				{
					breakpoint: 780,
					settings: {
						slidesToShow: 2
					}
				},
				{
					breakpoint: 1000,
					settings: {
						slidesToShow: 4
					}
				},
				{
					breakpoint: 1290,
					settings: {
						slidesToShow: 5
					}
				}
			]
		});
	});

	$('.article__img-carousel').each(function () {
		let $wrap = $(this),
			$slider = $wrap.find('.article__img-list'),
			$counter = $wrap.closest('.article__img').find('.time')
		;

		$slider
		.on('init', function (event, slick) {
			if( $counter.length ) $counter.html((slick.currentSlide+1) + '/' + slick.slideCount);
		})
		.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
			if( $counter.length ) $counter.html((nextSlide+1) + '/' + slick.slideCount);
		})
		.slick({
			dots: false,
			infinite: true,
			swipeToSlide: true,
			speed: 300,
			prevArrow: $wrap.find('.card__control-btn_prev'),
			nextArrow: $wrap.find('.card__control-btn_next'),
			variableWidth: false,
			slidesToScroll: 1
		});
	});

	let $b = $('body');

	$('input[type = tel]').each(function () {
		let inputmask = new Inputmask({
			mask: '+7 (999) 999-99-99',
			showMaskOnHover: false,
			onincomplete: function() {
				this.value = '';
				$(this).closest('.form__field').removeClass('f-filled');
			}
		});
		inputmask.mask($(this)[0]);
	});

	$('[data-mask]').each(function () {
		let $t = $(this),
			inputmask = new Inputmask({
				mask: $t.attr('data-mask'),
				placeholder: " ",
				showMaskOnHover: false,
				onincomplete: function() {
					this.value = '';
					$t.closest('.form__field').removeClass('f-filled');
				}
		});
		inputmask.mask($(this)[0]);
	});


	$b

		.on('click', function (e) {

			closeOuterClick('.nav__sort-list');
			closeOuterClick('.header__user');

			function closeOuterClick(el) {
				if ( $(el).filter('.is-open').length && !$(e.target).closest(el).length ){
					$(el).filter('.is-open').removeClass('is-open');
				}
			}
		})

		.on('click', '[data-scroll]', function (e) {
			let $t = $(this),
				hash = $t.attr('data-scroll');

			if ( $(hash).length > 0){
				e.preventDefault();
				$('html,body').animate({scrollTop:$(hash).offset().top + 1}, 500);
			}
		})
		.on('click', '.header__handler', function (e) {
			$b.toggleClass('is-menu-open');
		})

		.on('click', '.js-popup-login', function (e) {
			let pop = window.app.popup.open('#authPopup');
			$(pop).find('[data-tab-link=auth_login]').click();
		})
		.on('click', '.js-popup-reg', function (e) {
			let pop = window.app.popup.open('#authPopup');
			$(pop).find('[data-tab-link=auth_reg]').click();
		})

		.on('keyup', '.search input', function (e) {
			let $input = $(this),
				$wrap = $input.closest('.search')
			;

			if ( $.trim($input.val()).length ){
				$wrap.addClass('is-fill')
			} else {
				$wrap.removeClass('is-fill')
			}
		})

		.on('focus', '.search input', function (e) {
			let $input = $(this),
				$wrap = $input.closest('.search')
			;

			if ( !$wrap.hasClass('is-open') ){
				$wrap.addClass('is-open')
			}
		})

		.on('blur', '.search input', function (e) {
			let $input = $(this),
				$wrap = $input.closest('.search')
			;

			if ( $wrap.hasClass('is-open') && !$wrap.hasClass('is-fill') ){
				$wrap.removeClass('is-open')
			}
		})

		.on('click', '.header__user-logo', function (e) {
			let $t = $(this),
				$wrap = $t.closest('.header__user')
			;

			if ($wrap.hasClass('is-open')){
				$wrap.removeClass('is-open');
			} else {
				$wrap.addClass('is-open');
			}

		})


		.on('click', '.search__ico', function (e) {
			let $t = $(this),
				$wrap = $t.closest('.search'),
				$input = $wrap.find('input')
			;

			if ($wrap.hasClass('is-open')){
				$wrap.removeClass('is-open is-fill');
				$input.val('');
			} else {
				$wrap.addClass('is-open');
				$input.focus();
			}

		})

		.on('click', '.nav__sort-item', function (e) {
			let $t = $(this),
				$wrap = $t.closest('.nav__sort-list')
			;

			if ( $wrap.hasClass('is-open') ){
				$t.addClass('is-active').siblings().removeClass('is-active');
				$wrap.removeClass('is-open');
			} else {
				e.preventDefault();
				$wrap.addClass('is-open');
			}

		})




		.on('blur', '.form__field input, .form__field textarea', function (e) {
			let $input = $(this),
				$field = $input.closest('.form__field');
			$field.removeClass('f-focused');

			if ( !$.trim($input.val()) ){
				$field.removeClass('f-filled');
			} else {
				$field.addClass('f-filled');
			}
		})

		.on('change', '.popup__conditer-item-input', function(e){
			let $t = $(this),
				$popup = $t.closest('.popup'),
				data = $t.closest('.popup__conditer-item').attr('data-item'),
				parse
			;

			$popup.find('.popup__conditer-item').removeClass('is-active');
			$t.closest('.popup__conditer-item').addClass('is-active');

			try{
				parse = JSON.parse(data);
			} catch (e){
				console.error('ошибка парсинга', e);
			}
			if ( data && parse){
				$.each(parse, (key, val)=>{

					let $el = $(`[data-${key}]`);

					if (val && val !== false && val !== 'false'){
						$el.html(val).closest('[data-wrap]').removeClass('is-hidden');
					} else if ( val === false || val === 'false' ){
						$el.html('').closest('[data-wrap]').addClass('is-hidden');
					}

				})
			}
		})
		.on('mouseover', '.hint', function(e){
			let $t = $(this),
				$content = $t.find('.hint__content')
			;

			$t.addClass('is-hover');
			$content.removeAttr('style');

			if( $t.offset().left + $content.outerWidth(true) + 40 >  window.innerWidth ){
				$content.css({marginLeft: window.innerWidth - ($t.offset().left + $content.outerWidth(true) + 40) + 'px' })
			}

		})
		.on('mouseleave', '.hint', function(e){
			let $t = $(this),
				$content = $t.find('.hint__content')
			;
			$t.removeClass('is-hover');

		})

	//oldprice: '43 340₽', price: '29 900₽', installment: '7475 ₽/мес', plan: 'Бизнес'}
		.on('submit', '.form', function (e) {
			/*
			let $form = $(this),
				$item = $form.find('input'),
				wrong = false
			;

			$item.each(function () {
				let input = $(this), rule = $(this).attr('data-require');
				$(input)
					.closest('.form__field')
					.removeClass('f-error f-message')
					.find('.form__message')
					.html('')
				;

				if ( rule ){
					form.validate(input[0], rule, err =>{
						if (err.errors.length) {
							wrong = true;
							$(input)
								.closest('.form__field')
								.addClass('f-error f-message')
								.find('.form__message')
								.html(err.errors[0])
							;
						}
					})
				}
			});

			if ( wrong ){
				e.preventDefault();
			} else if( $form.hasClass('form_reg') && !$form.attr('data-registered') ) {
				e.preventDefault();
				$.ajax({
					url: $form.attr('data-check-url'),
					data: $form.serialize()
				}).done(function(data){
					if (data.status === true){
						$form
							.attr('data-registered', 'true')
							.closest('.popup__auth')
							.addClass('is-registered')
							.find('.form_pass')
							.find('input[type = email]')
							.val($form.find('input[type = email]').val())
						;
					} else {
						$form.attr('data-registered', 'false');
						$form.submit();
					}
				}).fail(function(err){
					$form.submit();
				});
			}
			*/
		})
	;


	(()=>{

		let $nav = $('.nav'),
			$window = $(window)
		;
		if ( $nav.length ){
			$window.on('scroll resize', ()=>{
				if ( $window.scrollTop() > $nav.offset().top && !$nav.hasClass('is-fixed')){
					$nav.addClass('is-fixed');
				} else if ( ($window.scrollTop() <= $nav.offset().top) && $nav.hasClass('is-fixed')) {
					$nav.removeClass('is-fixed');
				}
			});
		}


	})();


	$(window).on('load', ()=>{
		setTimeout(()=>{$(window).trigger('resize');}, 5000);
	})




	// $('.popup__conditer-courses').each(function () {
	// 	let $slider = $(this);
	//
	// 	$slider.slick({
	// 		dots: false,
	// 		infinite: false,
	// 		swipeToSlide: true,
	// 		speed: 300,
	// 		arrows: false,
	// 		variableWidth: false,
	// 		slidesToScroll: 1,
	// 		mobileFirst: true,
	//
	// 		responsive: [
	// 			{
	// 				breakpoint: 780,
	// 				settings: {
	// 					slidesToShow: 3
	// 				}
	// 			}
	// 		]
	// 	});
	// });

});

