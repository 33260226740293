import $ from 'jquery'
require('malihu-custom-scrollbar-plugin')($);

export default class Scrollbar {
	constructor(options){
		this._polyfill();
		Object.assign(this._options = {}, this._default(), options);
	}

	_default() {

		let that = this;

		return {

			classes: {
				onScrollStart: 'is-scrollbar-start',
				onScrollEnd: 'is-scrollbar-end'
			},

			callbacks: {
				onCreate: function(){
					$(this).attr('data-scrolled', true).addClass(that._options.classes.onScrollStart);
				},
				whileScrolling: function () {
					let progress = this.mcs.topPct >= 0 ? this.mcs.topPct : this.mcs.leftPct;

					if (progress === 0){
						$(this).addClass(that._options.classes.onScrollStart);
					} else if (progress === 100){
						$(this).addClass(that._options.classes.onScrollEnd);
					} else {
						$(this).removeClass(`${that._options.classes.onScrollStart} ${that._options.classes.onScrollEnd}`);
					}
				}
			}
		}
	}

	init(el, opts){
		let options = Object.assign({}, this._options, (opts || {}));
		$(el).not('[data-scrolled]').mCustomScrollbar(options);
	}

	update(el){
		$(el).filter('[data-scrolled]').mCustomScrollbar('update');
	}

	destroy(el){
		$(el)
			.filter('[data-scrolled]')
			.mCustomScrollbar('destroy')
			//.removeClass(`mCS_destroyed ${this._options.classes.onScrollStart} ${this._options.classes.onScrollEnd}`)
			.removeAttr('data-scrolled');
	}

	_polyfill() {
		//assign
		if (typeof Object.assign !== 'function') {
			Object.assign = function (target, varArgs) { // .length of function is 2
				'use strict';
				if (target === null) { // TypeError if undefined or null
					throw new TypeError('Cannot convert undefined or null to object');
				}

				var to = Object(target);

				for (var index = 1; index < arguments.length; index++) {
					var nextSource = arguments[index];

					if (nextSource !== null) { // Skip over if undefined or null
						for (var nextKey in nextSource) {
							// Avoid bugs when hasOwnProperty is shadowed
							if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
								to[nextKey] = nextSource[nextKey];
							}
						}
					}
				}
				return to;
			};
		}

	}
}