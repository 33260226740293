import $ from 'jquery'


/**
 * .image__wrap: img(src='img/temp/photo_01.jpg')
 *
 * css
 *
 * .image__wrap{
 * 		width: XXXpx;
 * 		height: YYYpx;
 * 		background-size: cover;
 * }
 *
 *
 * .image__wrap img{
 * 		width: 100%;
 * 		height: 100%;
 * }
 *
 * .obj-fit img{
 * 		opacity: 0;
 * }
 *
 *
 */

export default class ObjectFit {
	constructor(options) {

		this._polyfill();

		Object.assign(this._options = {}, this._default(), options);
		if (document.readyState === 'loading') {
			document.addEventListener('DOMContentLoaded', () => {
				this.init();
			});
		} else {
			this.init();
		}
	}

	_polyfill() {
		if (!Object.assign) {
			Object.defineProperty(Object, 'assign', {
				enumerable: false,
				configurable: true,
				writable: true,
				value: function (target, firstSource) {
					'use strict';
					if (target === undefined || target === null) {
						throw new TypeError('Cannot convert first argument to object');
					}

					let to = Object(target);
					for (let i = 1; i < arguments.length; i++) {
						let nextSource = arguments[i];
						if (nextSource === undefined || nextSource === null) {
							continue;
						}

						let keysArray = Object.keys(Object(nextSource));
						for (let nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
							let nextKey = keysArray[nextIndex],
								desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
							if (desc !== undefined && desc.enumerable) {
								to[nextKey] = nextSource[nextKey];
							}
						}
					}
					return to;
				}
			});
		}
	}

	_default(){
		return{
			imageContainer: '.obj-fit'
		}
	}

	isIe() {
		let userAgent, ieReg;
		userAgent = window.navigator.userAgent;
		ieReg = /msie|Trident.*rv[ :]*11\./gi;
		return ieReg.test(userAgent);
	}

	init(){

		if(this.isIe()) {
			$(this._options.imageContainer).each(function () {
				let $container = $(this),
					imgUrl = $container.find('img').prop('src')
				;

				if (imgUrl) {
					$container.css("backgroundImage", 'url(' + imgUrl + ')').addClass('object-fit');
				}
			});
		}

	}
}
